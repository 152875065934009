.announcement-container {
    width: 100%;
    background: #E9E9E9 0% 0% no-repeat padding-box;
    height: 60px;
    margin: 40px 0;
    border-radius: 25px;
    overflow: hidden;
    display: flex;
    marquee {
        display: flex;
        align-items: center;
    }
    .announcement-text {
        background-color: #04253d;
        height: 60px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: fit-content;
        padding: 0 20px;
        font-family: var(--primary-font);
    }

    .announcement-marquee-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 20px;
        font-weight: 600;
        font-family: var(--primary-font);
    }

    .marquee-dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #F28A3A;
        margin-right: 5px;
        margin-left: 50px;
    }
}

body.rtl{
    .marquee-dot {
        margin-right: 50px;
        margin-left: 5px;
    }
}

@media only screen and (max-width: 600px) {
    .announcement-container {
        .announcement-text {
            font-size: 14px;
            padding: 0 10px;
        }

        .marquee-dot {
            font-size: 14px;
        }
    }
}