.btn.btn-primary {
  background-color: transparent;
  color: #fff;
  padding:  10px 30px;
  border-radius: 100px;
  border: 1px solid #fff;
  position: relative;
  min-width: 190px;
  text-align: left;
  
 
  &:hover{
    background-color: var(--primary-color);
    color: #fff;
    img{
      right: 20px;
        // transition: padding-left 1s .5s;
        transition: .2s ease;
    }
  }
  &:focus{
    background-color: var(--primary-color) !important;
    border: 1px solid #fff !important;
    box-shadow: none !important;
    outline: none;
  }
}

// SECONDARY

.btn.btn-secondary {
  background-color: var(--primary-color);
  color: #fff;
  padding:  10px 30px;
  border-radius: 100px;
  border: 1px solid var(--primary-color);
  position: relative;
  min-width: 172px;
  text-align: left;
 
  &:hover{
    background-color: var(--primary-color);
    color: #fff;
    border: 1px solid var(--primary-color);
    img{
      right: 20px;
        // transition: padding-left 1s .5s;
        transition: .2s ease;
    }
  }
  &:focus{
    background-color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
    box-shadow: none !important;
    outline: none;
  }
}

// WARNING

.btn.btn-warning {
  background-color: #F28A3A;
  color: #fff;
  padding:  10px 30px;
  border-radius: 100px;
  border: 1px solid #F28A3A;
  position: relative;
  min-width: 140px;
  text-align: left;
 
  &:hover{
    background-color: var(--primary-color);
    color: #fff;
    border: 1px solid var(--primary-color);
    img{
      right: 20px;
        // transition: padding-left 1s .5s;
        transition: .2s ease;
    }
  }
  &:focus{
    background-color: #F28A3A !important;
    border: 1px solid #F28A3A !important;
    box-shadow: none !important;
    outline: none;
    color: #fff;
  }
}

.btn-icon{
  height: 15px;
  width: 40px;
  padding-left: 10px;
  position: absolute;
  right: 25px;
  top: 17px;
  transition: .2s ease;
}

