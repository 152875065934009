section.humanRights__whatWeDo {
  padding: 20px 60px;
  margin-bottom: 30px;
  background-color: var(--primary-color);
  border-radius: 10px;
  color: #fff;

  .swiper-pagination {
    bottom: 0 !important;
  }

  .swiper {
    height: auto;
  }

  .whatWeDo__contents {
    padding: 30px;
  }

  .grid__lists {
    color: #fff;

    img.whatwedo {
      height: 500px;
      object-fit: contain;
    }

    .title {
      display: flex;
      align-items: center;
      width: 100%;

      h2 {
        font-family: var(--primary-font);
        font-size: 30px;
        font-weight: 400;
        padding-left: 20px;
        margin-bottom: 0;
        line-height: 1;
      }

      span {
        color: var(--secondary-color);
        font-size: 80px;
        font-family: var(--primary-font);
        font-weight: bold;
      }
    }

    .content {
      p {
        font-weight: 100;
        -webkit-line-clamp: 6;
        line-clamp: 6;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 20px;
        font-size: 16px;
      }

      a.know-more {
        color: var(--secondary-color);
        font-size: 15px;
        margin-bottom: 0;
        transition: 0.7s;

        img {
          width: 25px;
          transition: 0.7s;
          padding-left: 5px;
        }

        &:hover {
          padding-left: 5px;
          transition: 0.7s;
        }
      }
    }
  }
}

body.rtl {
  section.humanRights__whatWeDo {
    a.know-more {
      &:hover {
        padding-right: 5px;
        transition: 0.7s;
      }

      img {
        transform: rotate(180deg);
        transition: 0.7s;
      }
    }
  }
}