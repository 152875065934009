#About_Us {
    padding: 80px 0;

    .heading {
        font-size: 34px;
        font-weight: 400;
        font-family: var(--primary-font);
        color: var(--primary-color);
        margin-top: 20px;
        margin-bottom: 0;
        line-height: 44px;
    }

    .description {
        font-size: 18px;
        color: var(--primary-color);
        -webkit-line-clamp: 5;
        line-clamp: 5;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;

        p {
            &:first-child {
                font-size: 18px;
                margin-bottom: 0;
                display: block;
                -webkit-line-clamp: 5;
                line-clamp: 5;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 5px;
            }

            display: none;
        }
    }

    .logo__about {
        min-height: 177px;
        display: flex;
        align-items: center;
        border-radius: 6px;
        justify-content: center;
    }

    .home__about {
        a.know-more img {
            height: 10px;
        }

        a.know-more {
            color: #ff6f00;
        }

    }

    .logo__about img {
        height: 79px;
    }
}