
.Titlebar_btn{
    display: flex;
    align-items: end;
    .btn{
        margin-bottom: 30px;
    }
}
.titleBar__section{
    display: flex;
    width: 100%;
    align-items: end;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 106px;
    padding-top: 50px;
    h2{
        text-transform: uppercase;
        font-family: var(--primary-font);
        font-weight: bold;
        color: var(--primary-color);
        font-size: 35px;
    }
    ul{
        padding: 0;
        list-style-type: none;
        display: inline-flex;
        margin-bottom: 5px;
        li{
            margin-right: 10px;
            position: relative;
            padding-left: 20px;
            font-family: var(--secondary-font);
            font-weight: 600;
            opacity: .5;
            cursor: pointer;
            &:first-child{
                padding-left: 0px;
                &::after{
                    display: none;
                }
            }
            &::after{
                content: '/';
                position: absolute;
                left: 0;
                padding-right: 10px;

            }
        }
    }
}


