.news-grid {
  width: 100%;
  height: 100%;
  // background-color: red;
  .content {
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h6 {
    font-size: 13px;
    text-align: left;
    letter-spacing: 0.75px;
    color: #66686e;
    text-transform: uppercase;
    opacity: 1;
    font-family: var(--primary-font);
  }

  h2 {
    font-size: 15px;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
    line-height: 1.3;
    font-family: var(--primary-font);
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 35px;
  }

  p {
    font-size: 14px;
    text-align: left;
    letter-spacing: 0.75px;
    color: #000000;
    opacity: 1;
    font-family: var(--secondary-font);
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
