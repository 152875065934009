.intro-container {
    // height: 300px;
    width: 100%;
    background-color: var(--primary-color);
    border-radius: 10px;
    color: white;
    img.intro-img{
        border-radius: 10px 0 0 10px;
        height: 330px;
        object-fit: cover;
    }

    .text-container {
        padding: 5%;
    }

    .title {
        font-size: 30px;
        line-height: 1.2;
        font-family: var(--primary-font);
        font-weight: 50;
    }

    .description {
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0px;
        opacity: 0.94;
        -webkit-line-clamp: 5;
      line-clamp: 5;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
    }
}

@media only screen and (max-width: 1400px) {
    .intro-container {
        .title {
            font-size: 23px;
        }

        .text-container {
            padding: 10px 40px;
        }
    
    }

}

@media only screen and (max-width: 992px) {
    .intro-container {
        .title {
            font-size: 18px;
        }

        .text-container {
            padding: 9%;
        }

    }
}