section.humanRights__news {
  padding: 60px 0;
  padding-top: 0;
  .news-grid {
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    &:hover h2 {
      color: var(--secondary-color);
      transition: all 0.3s linear;
    }
    cursor: pointer;
    .section-img {
      position: relative;
      overflow: hidden;
      border-radius: 10px;
    }
    img {
      transition: all 0.3s linear;
      width: 100%;
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 10px;
      filter: grayscale(1);
    }
    &:hover img {
      transform: scale(1.1);
      filter: grayscale(0);
      transition: all 0.3s;
    }
  }
}
