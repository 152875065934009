.logo-image {
  height: 60px;
}
.navbar-light .navbar-text a {
  color: var(--primary-color) !important;
}

.nav-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background-color: #fafafa;
}
.language__switch {
  display: flex;
  padding: 0;
  list-style-type: none;
  margin-bottom: 0;
  li {
    margin-left: 10px;
    font-family: var(--primary-font);
    font-weight: 400;
    border-left: 2px solid var(--secondary-color);
    padding-left: 10px;
    color: var(--primary-color);
    height: 17px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &.active {
      color: var(--primary-color);
      font-weight: 600;
    }
    &:first-child {
      border-left: none;
      padding-left: 0;
      margin: 0;
    }
    &.contact {
      font-weight: 500;
    }
  }
}
.main__header {
  padding: 12px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.main__header.scrolled {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  padding: 0px 12px;
  background-color: #fff;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  box-shadow: 0 6px 15px #404f680d;
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@keyframes navLinkFade {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 600px) {
  .logo-image {
    height: 40px;
  }
}

// MENU STYLES
.menu__items {
  background-color: var(--primary-color);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 9999;
  color: #fff;
  padding: 35px 100px;
  transition-duration: 0.4s;

  .header__logo {
    position: relative;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      img {
        width: 220px;
        position: relative;
        z-index: 9999;
      }
    }
    .closeMenu {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      h6 {
        margin: 0;
        font-weight: 500;
        font-size: 16px;
        font-family: var(--secondary-font);
        text-transform: uppercase;
      }
      img {
        height: 30px;
        cursor: pointer;
      }
    }
  }
  .menu__contact {
    min-height: 80vh;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
  }
  .menu__links {
    height: 80vh;
    display: flex;
    align-items: center;
    margin-top: 50px;
    counter-reset: css-counter 0;
    span {
      counter-increment: css-counter 1;
      font-size: 14px;
      margin-right: 8px;
      color: var(--secondary-color);
      &:before {
        content: "0" counter(css-counter);
      }
    }
    span[data-hide-zero="true"]:before {
      content: "" counter(css-counter);
    }

    ul {
      padding: 0;
      list-style-type: none;
     &:first-child{
      margin-right: 80px;
     }

      li {
        font-size: 30px;
        font-family: var(--secondary-font);
        font-weight: 400;
        padding-bottom: 22px;
        display: flex;
        align-items: baseline;

        a {
          color: #fff;
          text-transform: capitalize;
          text-decoration: none;

          &.active {
            opacity: 1;
            color: var(--secondary-color);
          }
        }
      }
    }

    &::after {
      content: "";
      width: 1px;
      height: 0;
      background: rgba(255, 255, 255, 0.1);
      position: absolute;
      right: 50px;
      top: -75vh;
      -webkit-transition: all 1s ease;
      -o-transition: all 1s ease;
      transition: all 1s ease;
      height: 200vh;
      -webkit-transition-delay: 1s;
      -o-transition-delay: 1s;
      transition-delay: 1s;
    }
    .search__box {
      width: 80%;
      h2 {
        text-transform: uppercase;
        text-transform: uppercase;
        font-size: 26px;
        font-family: var(--secondary-font);
        color: var(--secondary-color);
        font-weight: 600;
      }
      .form-control {
        border: 0.5px solid rgba(255, 255, 255, 0.337254902);
        border-radius: 7px;
        opacity: 0.88;
        margin-bottom: 30px;
        background-color: transparent;
        padding: 14px 20px;
        min-height: 50px;
        font-weight: 200;
        color: white;
        &:focus {
          box-shadow: none;
        }
      }
      .form-control::-ms-input-placeholder {
        color: #fff;
      }

      .form-control::placeholder {
        color: #fff;
      }
    }
  }
  .menu__contact {
    margin-top: 50px;
    .contact__details {
      margin-bottom: 20px;
      width: 50%;
      h3 {
        text-transform: uppercase;
        font-size: 18px;
        font-family: var(--secondary-font);
        color: var(--secondary-color);
        font-weight: 600;
      }
      p {
        font-family: var(--secondary-font);
        font-size: 16px;
      }
      a {
        color: #fff !important;
      }
      ul {
        display: inline-flex;
        padding: 0;
        text-decoration: none;
        list-style-type: none;
        li {
          margin-right: 10px;
          font-family: var(--secondary-font);
          font-size: 16px;
          a {
            text-decoration: none;
            color: #fff;
            img{
              width: 38px;
              padding-top: 10px;
              padding-right: 4px;
            }
          }
        }
      }
    }
  }
}

.nav-fade {
  animation: navLinkFade 0.5s ease forwards;
  transition: all 0.7s ease-in-out !important;
}
@keyframes navLinkFade {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.nav-fade {
  transform: translateY(30px);
  opacity: 0;
  transition: all 0.7s ease-in-out !important;
}
.navbar__item {
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.nav-fade:first-of-type {
  animation-delay: 0.2s;
}

.nav-fade:nth-of-type(2) {
  animation-delay: 0.4s;
}

.nav-fade:nth-of-type(3) {
  animation-delay: 0.6s;
}

.nav-fade:nth-of-type(4) {
  animation-delay: 0.8s;
}

// RESPONSiVE
@media only screen and (max-width: 600px) {
  .language__switch {
    align-items: center;
    font-size: 18px;
  }
  .language__switch li:nth-child(2) {
    border-left: none !important;
  }
  .main__header {
    img.icon__header {
      height: 20px;
    }
  }
  section.humanRights__whatWeDo .what-we-do-text-container {
    width: 100% !important;
  }
  .menu__items .header__logo .closeMenu h6 {
    font-size: 14px;
  }
  .menu__items .header__logo .closeMenu img {
    height: 23px;
  }
  .menu__items .header__logo .logo img {
    width: 150px;
  }
  .sm-none {
    display: none !important;
  }
  .language__switch li.active {
    border-left: none;
    margin-left: 0;
    padding-left: 0;
  }
  .menu__items {
    padding: 30px;
    overflow-y: auto;
    .menu__contact {
      margin-top: 0;
    }

    .menu__contact {
      min-height: 46vh;
      justify-content: center;
      .contact__details {
        width: 100%;
        p {
          font-size: 14px;
          margin-bottom: 0;
        }

        h3 {
          font-size: 15px;
        }
        ul {
          li {
            font-size: 14px;
          }
        }
      }
    }
    .menu__links {
      width: 100%;
      height: auto;
      &::after {
        display: none;
      }
      ul {
        margin-right: 34px;
        li {
          font-size: 15px;
          display: flex;
          padding-bottom: 11px;
          align-items: center;
        }
      }
    }
  }
}

/* TABLET */

@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* IPAD PRO */
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
}

/* 13 INCH SCREEN */
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
}
