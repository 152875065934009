.confirmation_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;

  img {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }

  h4 {
    font-size: 35px;
    margin-bottom: 12px;
    letter-spacing: 0px;
    color: #000000;
    font-family: var(--secondary-font);
    font-weight: 600;
  }

  p {
    font-size: 16px;
    margin-bottom: 1.5rem;
    max-width: 400px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    text-align: center;
  }

  .back_home {
    button {
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 600;
      color: #fff;
      padding: 0.75rem 1.5rem;
      background: var(--primary-color)0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      border-radius: 32px;
      cursor: pointer;
      transition: background 0.3s ease;
      font-family: var(--primary-font);

      //   &:hover {
      //     background: darken(#0a1f44, 10%);
      //   }

      img {
        width: 50px;
        height: 1rem;
        margin-bottom: 0px;
      }
    }
  }
}
