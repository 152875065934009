.home__page {

  .modal {
    &-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }

    &-content {
      background: white;
      border-radius: 12px;
      padding: 2.5rem;
      width: 70%;
      // max-width: 700px;
      position: relative;
      max-height: 90vh;
      overflow-y: auto;
    }

    &-close {
      position: absolute;
      top: 28px;
      right: 1rem;
      background: none;
      border: none;
      cursor: pointer;

      &:hover {
        color: var(--secondary-color);
      }

      img {
        width: 35px;
        height: 30px;
      }
    }

    &-breadcrumb {
      letter-spacing: 0px;
      color: var(--primary-color);
      opacity: 0.5;
      margin-bottom: 5px;
      font-family: var(--secondary-font);
      font-size: 16px;

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &-title {
      color: var(--primary-color);
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 10px;
      font-family: var(--primary-font);
    }

    &-progress {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 24%;
        left: 94px;
        right: 68px;
        height: 0;
        border-top: 2px dotted #e5e7eb;
        z-index: 1;
      }

      &-step {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 2;

        &-icon {
          width: 54px;
          height: 54px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #dadbe1;
          margin-bottom: 0.5rem;

          img {
            width: 30px;
            height: 30px;
          }

          &.active {
            background: #f4804f !important;
            color: white !important;

            img {
              filter: brightness(20);
            }
          }

          &.completed {
            background: #e5e7eb;
            color: #0a1f44;
          }
        }

        &-label {
          color: var(--primary-color);
          font-size: 13px;
          text-align: center;
          max-width: 140px;
          font-family: var(--secondary-font);
          font-weight: 500;
        }

        &-sublabel {
          color: #545454;
          font-size: 12px;
          text-align: center;
          font-family: var(--tertiary-font);
        }
      }
    }

    &-amount {
      margin-bottom: 2rem;

      &-title {
        color: var(--primary-color);
        // font-size: 26px;
        margin-bottom: 1rem;
        font-family: var(--secondary-font);
        font-family: 500;
      }

      &-slider {
        width: 100%;
        margin-bottom: 1rem;

        input[type="range"] {
          width: 100%;
          -webkit-appearance: none;
          height: 4px;
          background: #e5e7eb;
          border-radius: 2px;
          background-image: linear-gradient(#f4804f, #f4804f);
          // background-size: 30% 100%;
          background-repeat: no-repeat;

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            background: #f4804f;
            cursor: pointer;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }
        }
      }

      &-input {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5rem;

        input {
          width: 140px;
          padding: 5px;
          border: 1px solid #6f7071;
          border-radius: 5px;
          opacity: 0.6;
          text-align: right;
        }

        span {
          color: #000000;
          font-family: var(--secondary-font);
          font-weight: 600;
          font-size: 12px;
        }
      }
    }

    &-reason {
      margin-bottom: 1rem;

      &-label {
        color: #000000;
        font-size: 16px;
        margin-bottom: 0.5rem;
        font-family: var(--secondary-font);
        font-weight: 500;
      }

      textarea {
        width: 100%;
        min-height: 100px;
        padding: 0.75rem;
        border: 1px solid #e5e7eb;
        border-radius: 4px;
        resize: vertical;

        &:focus {
          outline: none;
          border-color: #f4804f;
        }
      }
    }

    &-next {
      display: flex;
      justify-content: flex-end;

      button {
        color: white;
        padding: 0.75rem 2rem;
        background: #04253d 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 32px;
        opacity: 1;
        font-size: 1rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-family: var(--primary-font);
        font-weight: 500;

        //   &:hover {
        //     background: darken(#0a1f44, 5%);
        //   }

        img {
          width: 50px;
          height: 1rem;
          margin-bottom: 0px;
        }
      }
    }
  }

  .divider {
    border: 0.5px solid #e5e7eb;
    width: 100%;
    margin-bottom: 25px;
  }

  .amount__field::-webkit-inner-spin-button,
  .amount__field::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  @media screen and (max-width: 600px) {
    .modal {
      &-content {
        width: 95%;
      }

      &-progress {
        &::after {
          content: "";
          position: absolute;
          top: 20%;
          left: 65px;
          right: 55px;
          height: 0;
          border-top: 2px dotted #e5e7eb;
          z-index: 1;
        }
      }
    }

    .modal-progress-step-icon {
      img {
        height: 20px !important;
        width: 20px !important;
      }
    }
  }
}