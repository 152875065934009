@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Condensed:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color: #04253D; 
  --secondary-color: #F28A3A; 
  --primary-font: 'Archivo', sans-serif;
  --secondary-font: 'Montserrat', sans-serif; 
  --tertiary-font: 'Archivo Condensed', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--primary-font);
}

.pointer {
  cursor: pointer;
}

/* ANIMATIONS */
.swiper-button-lock{
  display: none;
}
