.otp {
  &-title {
    font-size: 20px;
    color: #000000;
    margin-bottom: 20px;

    text-align: left;
    font-family: var(--secondary-font);
    font-weight: 600;
    letter-spacing: 0px;
    opacity: 1;
  }

  &-section {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;

    &-title {
      font-size: 16px;
      font-family: var(--secondary-font);
      color: var(--primary-color);
      margin-bottom: .75rem;
    }

    &-subtitle {
      letter-spacing: 0px;
      color: #000000;
      opacity: 0.46;
      font-size: 14px;
      margin-bottom: 0.5rem;
    }
  }

  &-inputs {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.5rem;

    input {
      width: 40px;
      height: 40px;
      text-align: center;
      font-size: 1.25rem;
      border: 1px solid #6f7071;
      border-radius: 5px;
      opacity: 0.6;

      &:focus {
        outline: none;
        border-color: var(--secondary-color);
      }
    }
  }

  &-timer {
    color: #666;
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }

  &-submit {
    &-section {
      button {
        color: white;
        border: none;
        padding: 7px 15px;
        font-size: 18px;
        cursor: pointer;
        background: var(--secondary-color) 0% no-repeat padding-box;
        border-radius: 5px;
        opacity: 1;

        &:hover {
          background: darken(#f4804f, 5%);
        }

        &:disabled {
          background: #e5e7eb;
          cursor: not-allowed;
        }
      }
    }

    &-main {
      margin-top: 1rem;
      display: flex;
      justify-content: flex-end;

      button {
        background: var(--primary-color);
        color: white;
        border: none;
        border-radius: 2rem;
        padding: 0.75rem 3rem;
        font-size: 1rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        transition: background-color 0.2s;

        // &:hover {
        //   background: darken(#f4804f, 5%);
        // }

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.otp_validate {
  padding: 40px 20%;
  text-align: center;
  font-size: 16px;

  .form-group {
    text-align: left;
    margin-bottom: 10px;
    margin-right: 10px;

    .withIcon {
      position: relative;

      img.icon {
        height: 20px;
        margin-bottom: 0;
        position: absolute;
        top: 50%;
        left: -5%;
        transform: translate(-50%, -50%);
      }
    }

    label {
      color: #072439;
      opacity: 0.8;
      font-size: 13px;
      margin-bottom: 3px;
    }

    .form-control {
      font-size: 14px;

      &:focus {
        box-shadow: none;
        outline: none;
        border: 1px solid var(--primary-color);
      }
    }
  }

  .otp_inputs div {
    justify-content: center;
    margin-bottom: 10px;
  }

  img.check {
    height: 100px;
    margin-bottom: 10px;
  }

  .otp_inputs {
    input {
      width: 50px !important;
      height: 50px;
      margin: 10px;
      border-radius: 6px;
      border: 1px solid rgb(112 112 112 / 30%);
      color: var(--primary-color);
      font-size: 27px;
    }
  }

  h6 {
    text-transform: capitalize;
    font-weight: 400;
    margin-bottom: 5px;
  }
}

.otp_inputs {
  margin-top: 20px;

  h6 {
    text-transform: capitalize;
    color: #072439;
    font-size: 14px;
    margin-top: 20px;
  }

  div:first-child {
    justify-content: center;
    gap: 10px;

    input {
      width: 40px !important;
      height: 40px !important;
      border: 1px solid rgb(112, 112, 112, 0.6);
      border-radius: 6px;
      font-size: 30px;
      color: var(--primary-color);
    }
  }
}

.otp-submit-section {
  .submit__button {
    height: 40px;
    background-color: #f4804f;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    color: white !important;
  }

  .btn-resend {
    color: black !important;
    border: none !important;
    font-size: 13px;
  }
}

.btn-resend {
  font-size: 13px;
  width: fit-content;
}

.verification__text {
  color: black !important;
}
