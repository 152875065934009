.event-grid {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 11px;
  p {
    font-family: var(--primary-font);
    font-weight: 100 !important;
    margin-bottom: 0;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px !important;
  }

  .event-image {
    width: 100%;
    height: 210px;
    object-fit: cover;
    border-radius: 10px;
  }

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.5;
    z-index: 1;
  }

  h4 {
    color: white;
    z-index: 2;
    position: absolute;
    top: 10%;
    left: 10%;
    transition: 0.3s;
  }

  .title {
    color: white;
    z-index: 2;
    position: absolute;
    bottom: 10%;
    left: 10%;
    transition: 0.3s;
  }

  .arrow {
    z-index: 2;
    position: absolute;
    bottom: 10%;
    right: 10%;
    height: 18px;
    display: none;
    transition: 0.3s;
  }
}

.event-grid:hover {
  cursor: pointer;

  h4 {
    color: #f28b3cff;
  }

  .title {
    color: #f28b3cff;
  }

  .arrow {
    display: block;
  }
}
