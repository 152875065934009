// DEFAULT
* {
  padding: 0;
  margin: 0;
}

a {
  color: var(--primary-color);
  text-decoration: none;

  &:hover {
    color: var(--primary-color);
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--primary-font);
}

p {
  font-family: var(--secondary-font);
  font-size: 15px;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// ZOOM
.scale {
  .img__sec {
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    img {
      transition: all 0.3s linear;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  &:hover {
    img {
      transform: scale(1.05);
    }

    h2 {
      color: var(--secondary-color);
      transition: 0.3s;
    }
  }
}

section.humanRights__library {
  padding: 60px 0;

  .library__grid {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 230px;
    padding: 20px;
    color: #fff;
    position: relative;
    border-radius: 10px;
    cursor: pointer;

    .overlay,
    .library__contents {
      opacity: 0;
    }

    &:hover .overlay {
      opacity: 1;
      -webkit-transition: 0.5s ease-out;
      -moz-transition: 0.5s ease-out;
      -o-transition: 0.5s ease-out;
      transition: 0.5s ease-out;
    }

    &:hover .library__contents {
      opacity: 1;
      -webkit-transition: 0.5s ease-out;
      -moz-transition: 0.5s ease-out;
      -o-transition: 0.5s ease-out;
      transition: 0.5s ease-out;
    }

    .overlay {
      background-color: #0000007e;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      z-index: 0;
    }

    .library__contents {
      z-index: 99;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h3 {
        font-family: var(--secondary-font);
        font-size: 20px;
      }

      h6 {
        font-family: var(--primary-font);
        font-weight: 100;
        font-size: 17px;
        width: 60%;
      }
    }
  }
}

section.humanRights__about {
  padding-bottom: 30px;

  .about__featured {
    background-color: var(--primary-color);
    padding: 80px;
    color: #fff;
    font-family: var(--primary-font);
    border-radius: 15px;

    h2 {
      font-size: 30px;
      font-weight: 300;
      padding-bottom: 10px;
      // border-right: 3px solid var(--secondary-color);
      line-height: 1.4;
      margin: 0;
      padding-right: 20px;
    }

    p {
      font-family: var(--secondary-font);
      color: #ffffff;
      margin: 0;
    }
  }

  .vison__grids {
    padding-top: 60px;

    .grid__items {
      // border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
      padding: 40px 80px;

      .header {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        img {
          height: 40px;
          padding-right: 10px;
        }

        h3 {
          color: var(--primary-color);
          font-size: 30px;
          margin-bottom: 0;
        }
      }

      .description {
        p {
          color: var(--primary-color);
          font-size: 16px;
        }
      }

      &:nth-child(2) {
        border-right: none;
      }

      &:nth-child(4) {
        border: none;
      }

      &:nth-child(3) {
        border-bottom: none;
        border-bottom: 1px solid #eee;
      }
    }
  }
}

section.board__members {
  .member {
    padding: 40px 30px;
    border: 1px solid #707070;
    border-radius: 12px;
    margin-bottom: 30px;
    min-height: 380px;
    cursor: pointer;

    .avatar {
      text-align: center;
      margin-bottom: 20px;
    }

    img {
      width: 50%;
      margin: 0 auto;
      text-align: center;
      filter: grayscale(1);
      height: 125px;
      width: 125px;
      object-fit: cover;
      border-radius: 100px;
      object-position: top;
    }

    h3 {
      font-size: 22px;
      text-align: center;
      color: var(--primary-color);
      margin-bottom: 3px;
      font-family: var(--primary-font);
    }

    h6 {
      text-align: center;
      color: #05253d;
      font-family: var(--primary-font);
      font-weight: 300;
    }

    p {
      margin: 0;
      color: #05253d;
      font-family: var(--secondary-font);
      font-size: 14px;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      margin: 0;
      overflow: hidden;
      line-height: 1.6;
      text-overflow: ellipsis;
      margin-top: 10px;
    }

    &:hover {
      background-color: var(--primary-color);
      color: #fff;
      transition: background-color 0.4s ease;

      h3,
      p,
      h6 {
        color: #fff;
      }

      img {
        filter: grayscale(0);
      }
    }
  }
}

// MODAL
.member__modal {
  padding: 30px;
  position: relative;

  .user {
    text-align: center;
    font-family: var(--primary-font);

    h6 {
      color: #050505;
      font-weight: 400;
      margin-top: 5px;
      text-align: center;
    }

    h3 {
      font-size: 22px;
      margin-bottom: 3px;
      text-align: center;
    }

    .avatar {
      margin-bottom: 20px;

      img {
        margin: 0 auto;
        height: 100px;
        width: 100px;
        object-fit: cover;
        border-radius: 100px;
        object-position: top;
      }
    }

    ul {
      display: inline-flex;
      list-style-type: none;
      margin: 0;
      padding: 0;
      margin-top: 20px;

      li {
        margin: 0 6px;

        img {
          height: 40px;
        }
      }
    }
  }

  .description {
    margin-top: 25px;

    p {
      margin-bottom: 0;
      font-family: var(--secondary-font);
      font-size: 14px;
      color: #050505;
      opacity: 0.56;
      font-weight: 500;
    }
  }

  .modal-close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;

    img {
      height: 25px;
    }
  }
}

section.whatWeDo {
  .grid__lists {
    margin-bottom: 20px;
    border-radius: 15px;
    transition: transform 250ms;
    padding: 40px 60px;

    &:nth-child(odd) .row {
      flex-direction: row-reverse;
    }

    &:hover {
      background-color: var(--primary-color);
      color: #fff;
      cursor: pointer;
      padding: 40px 60px;
      border-radius: 15px;
      transition: background-color 0.4s ease;
    }

    .title {
      display: flex;
      align-items: center;
      width: 100%;

      span {
        color: var(--secondary-color);
        font-size: 80px;
        font-family: var(--primary-font);
        font-weight: bold;
      }

      h2 {
        font-family: var(--primary-font);
        font-size: 30px;
        font-weight: 400;
        padding-left: 20px;
        margin-bottom: 0;
        line-height: 1;
      }
    }

    .content {
      width: 95%;

      p {
        font-family: var(--secondary-font);
      }
    }
  }
}

// ve11adan //

section.news {
  .featured_news {
    margin-bottom: 30px;

    .news__grid {
      cursor: pointer;
      font-family: var(--primary-font);

      .img__sec {
        border-radius: 20px;
      }

      h6 {
        font-size: 14px;
        margin-bottom: 20px;
        margin-top: 10px;
        text-transform: uppercase;
      }

      h2 {
        margin-top: 10px;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
        text-transform: capitalize;
      }

      .content {
        color: var(--primary-color);
        opacity: 1;
        font-family: var(--secondary-font);
        font-size: 14px;
        word-wrap: break-word;
        word-break: break-word;
      }

      img {
        width: 100%;
        height: 400px;
        object-fit: cover;
        background-size: cover;
        border-radius: 20px;
        background-position: center;
      }
    }
  }

  .news__items {
    .news__grid {
      cursor: pointer;
      font-family: var(--primary-font);
      margin-bottom: 40px;

      h6 {
        font-size: 14px;
        color: #66686e;
        margin-top: 10px;
        text-transform: uppercase;
        font-weight: 300;
      }

      h2 {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 10px;
        text-transform: capitalize;
        // min-height: 37px;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .content {
        color: var(--primary-color);
        opacity: 1;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: var(--secondary-font);
        font-size: 14px;
        word-wrap: break-word;
        word-break: break-word;
      }

      p {
        color: #05253d;
        opacity: 1;
        font-family: var(--secondary-font);
        font-size: 14px;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
      }

      img {
        width: 100%;
        height: 230px;
        object-fit: cover;
        background-size: cover;
        border-radius: 10px;
        // padding-bottom: 10px;
        background-position: center;
      }
    }
  }
}

section.news__details {
  .details {
    margin-bottom: 60px;

    h6 {
      color: #9fa1aa;
      text-transform: uppercase;
      margin-bottom: 15px;
    }

    img {
      height: 550px;
      object-fit: cover;
      border-radius: 10px;
      margin-bottom: 30px;
    }

    p {
      font-weight: 500;
      margin-bottom: 40px;
      line-height: 1.8;
    }
  }
}

section.events {
  .event__grid {
    color: #fff;
    height: 220px;
    width: 100%;
    background-size: cover;
    border-radius: 10px;
    background-position: center;
    padding: 25px;
    position: relative;
    filter: grayscale(1);
    margin-bottom: 30px;
    transition: 0.5s;

    .overlay {
      position: absolute;
      background-image: linear-gradient(rgba(255, 0, 0, 0), #000);
      width: 100%;
      left: 0;
      bottom: 0;
      top: 0;
      opacity: 1;
      border-radius: 22px;
      transition: 0.5s;
    }

    &:hover {
      filter: grayscale(0);
      // cursor: pointer;
      transition: 0.5s;
      // cursor: pointer;
      transition: 0.5s all;
      transform: translateY(-2px);
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      position: relative;
      z-index: 2;

      .download {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 2;

        img {
          height: 36px;
          filter: grayscale(0) !important;
        }
      }

      h2 {
        font-size: 22px;
        margin-bottom: 0;
      }

      p {
        font-size: 18px;
        margin-bottom: 0;
        width: 90%;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

section.library {
  margin-bottom: 40px;

  .nav-tabs .nav-link.active {
    color: var(--primary-color);
    font-weight: 500;
    padding: 8px 25px;
    border-radius: 30px;
    border: 1px solid var(--primary-color);
    cursor: pointer;
    opacity: 1;
  }

  .nav-tabs .nav-link {
    font-weight: 500;
    font-size: 17px;
    padding: 8px 14px;
    border-radius: 30px;
    color: var(--primary-color);
    border: none;
    margin: 0 5px;
    opacity: 0.8;

    &:first-child {
      margin-left: 0;
    }
  }

  .nav-tabs {
    border-bottom: none !important;
  }

  .library__grid {
    position: relative;

    .library__item {
      margin-top: 10px;
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 230px;
      padding: 20px;
      color: #fff;
      position: relative;
      border-radius: 10px;
      cursor: pointer;

      .overlay,
      .library__contents {
        opacity: 0;
      }

      &:hover .overlay {
        opacity: 1;
        -webkit-transition: 0.5s ease-out;
        -moz-transition: 0.5s ease-out;
        -o-transition: 0.5s ease-out;
        transition: 0.5s ease-out;
      }

      &:hover .library__contents {
        opacity: 1;
        -webkit-transition: 0.5s ease-out;
        -moz-transition: 0.5s ease-out;
        -o-transition: 0.5s ease-out;
        transition: 0.5s ease-out;
      }

      .overlay {
        background-color: #0000007e;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        z-index: 0;
      }

      .library__contents {
        z-index: 99;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h3 {
          font-family: var(--secondary-font);
          font-size: 20px;
        }

        h6 {
          font-family: var(--primary-font);
          font-weight: 100;
          font-size: 19px;
          width: 100%;
        }
      }
    }
  }

  .latest__videos {
    padding-top: 10px;

    .featured__video {
      width: 100%;
      height: 600px;
      background-size: cover;
      border-radius: 20px;
      position: relative;
      font-family: var(--secondary-font);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;

      h2 {
        position: absolute;
        color: #fff;
        font-weight: 400;
        width: 49%;
        font-size: 45px;
        line-height: 1;
        left: 5%;
        bottom: 7%;
      }

      .overlay {
        background-color: #0000007e;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        z-index: 0;
      }

      .icon {
        position: relative;
        z-index: 99;
        cursor: pointer;

        img {
          height: 80px;
        }
      }
    }

    .videos__grid {
      width: 100%;
      height: 200px;
      background-size: cover;
      border-radius: 10px;
      position: relative;
      font-family: var(--secondary-font);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      .overlay {
        background-color: #0000007e;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        z-index: 0;
      }

      .icon {
        position: relative;
        z-index: 99;
        cursor: pointer;

        img {
          height: 40px;
        }
      }
    }

    .video-title {
      font-size: 18px;
      margin-bottom: 30px;
    }
  }
}

.captions {
  display: none;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

.fslightbox-open {
  .fslightbox-container {
    z-index: 999;
  }

  .captions {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: end;
    position: absolute;
    font-size: 50px;
    left: 0;
    bottom: 0;
    z-index: 9999999;
    font-family: var(--primary-font);
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;

    h2 {
      font-size: 24px;
      margin-bottom: 0;
      font-weight: 400;
    }

    p {
      font-size: 20px;
      margin-bottom: 0;
      width: 46%;
      line-height: 1.2;
      padding-left: 20px;
      border-left: 1px solid #fff;
      margin-left: 20px;
    }
  }
}

.library__reports {
  padding-top: 10px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  .library__image {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .library__image img {
    transition: all 0.3s linear;
    height: 200px;
    width: 100%;
    filter: grayscale(1);
    border-radius: 10px;
    object-fit: cover;
  }

  &:hover {
    .library__image img {
      transform: scale(1.1);
      filter: grayscale(0);
    }

    h2 {
      color: var(--secondary-color);
      transition: 0.4s ease-out;
    }
  }

  h2 {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 500;
    min-height: 43px;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .content {
    color: var(--primary-color);
    opacity: 1;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: var(--secondary-font);
    font-size: 14px;
    word-wrap: break-word;
    word-break: break-word;
  }
}

.report__details {
  margin-bottom: 60px;

  img {
    width: 100%;
    border-radius: 15px;
    max-height: 550px;
    object-fit: cover;
    margin-bottom: 30px;
  }

  p {
    font-weight: 500;
    margin-bottom: 40px;
    line-height: 1.8;
  }

  .content {
    color: var(--primary-color);
    opacity: 1;
    font-family: var(--secondary-font);
    font-size: 16px;
    word-wrap: break-word;
    word-break: break-word;
  }
}

section.local__events {
  .localEvent__grid.sm__grid {
    img.main {
      height: 200px;
      width: 100%;
    }

    h2 {
      font-size: 16px;
      margin-bottom: 10px;
    }

    p {
      font-size: 13px;
    }

    .localGrid {
      padding: 10px;

      .icon {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }

        img {
          padding-right: 5px;
        }

        p {
          margin: 0;
          font-weight: 500;
          color: #05253d;
          font-size: 10px;
          font-family: var(--primary-font);
          text-transform: uppercase;
        }
      }
    }
  }

  .localEvent__grid {
    margin-bottom: 50px;

    &.featured {
      h2 {
        min-height: 57px;
      }
    }

    img.main {
      height: 500px;
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
      margin-bottom: 15px;
    }

    h2 {
      font-size: 24px;
      margin-bottom: 20px;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .content {
      color: var(--primary-color);
      opacity: 1;
      -webkit-line-clamp: 6;
      line-clamp: 6;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: var(--secondary-font);
      font-size: 14px;
      word-wrap: break-word;
      word-break: break-word;
    }

    .description {
      font-size: 14px;
    }
  }

  .localGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: #f7f7f7;
    padding: 15px;
    margin-bottom: 10px;

    .icon {
      display: flex;
      align-items: center;
      margin-bottom: 0px;

      &:first-child {
        border-right: 0.5px solid #70707031;
        margin-right: 10px;
      }

      &:last-child {
        margin-bottom: 0;
        border-right: 0.5px solid #70707031;
        margin-right: 10px;
      }

      img {
        padding-right: 10px;
      }

      p {
        margin: 0;
        font-weight: 500;
        color: #05253d;
        font-size: 15px;
        font-family: var(--primary-font);
        text-transform: uppercase;
      }
    }
  }

  .localEvent__grid.details {
    .localGrid {
      margin-bottom: 20px;
    }

    p {
      -webkit-line-clamp: unset;
      line-clamp: unset;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p.mb-4 {
      font-weight: 500;
      margin-bottom: 40px;
      line-height: 1.8;
    }
  }
}

section.faq {
  margin-bottom: 60px;

  .accordion-button {
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .accordion-item {
    border: 1px solid #c8c8c8;
  }

  .accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0px 32px 64px #3914000a;
  }

  .accordion-item:first-of-type {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .accordion-item:last-of-type {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .accordion-item:last-of-type .accordion-body {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .accordion-button {
    padding: 30px 60px;
    font-family: var(--primary-font);
    font-weight: 500;
    color: #391400;
    border: 1px solid #c8c8c8;
    border-bottom: none;
    border-top: none;
  }

  .accordion-body {
    background-color: var(--primary-color);
    color: #fff;

    p {
      color: #fff;
    }
  }

  .accordion-button:not(.collapsed) {
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    box-shadow: none;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url("../../src/assets/images/up.svg");
    rotate: 180deg;
  }

  .accordion-button::after {
    background-image: url("../../src/assets/images/down.svg");
    width: 23px;
    height: 23px;
    background-size: contain;
  }

  .faq__details {
    p {
      margin: 0;
      font-family: var(--secondary-font);
      font-size: 14px;
    }

    padding: 20px 40px;
    font-weight: 500;
    color: var(--primary-color);
    padding-top: 0;
  }
}

.button-donation {
  display: flex;
  justify-content: flex-end;
}
  .btn-donate {
    color: white;
    padding: 10px 30px;
    background: var(--secondary-color) 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 32px;
    opacity: 1;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: var(--primary-font);
    font-weight: 500;
    transition: background-color 0.3s ease, transform 0.3s ease;

    // &:hover {
    //   // background: lighten(var(--secondary-color), 10%); // Slightly lighten the background on hover
    //   transform: scale(1.02); // Slight zoom-in effect
    // }
  }
    .icon-donate {
      width: 40px;
      height: 15px;
      margin-bottom: 0px;
      transition: transform 0.3s ease;

      // &:hover {
      //   transform: translateY(-3px) scale(1.1); // Move up and slightly enlarge
      // }
    }
  


section.contact_us {

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  padding-bottom: 60px;

  .contact__details {
    background-color: var(--primary-color);
    color: #fff;
    padding: 80px;
    border-radius: 40px 40px 0 0;

    .address_grid {
      display: flex;
      align-items: start;
      padding: 0 50px;
      margin-bottom: 50px;

      &:last-child {
        margin-bottom: 0;
      }

      .icon {
        padding-right: 20px;

        img {
          height: 60px;
        }
      }

      .content {
        width: 100%;
        opacity: 0.8;

        h4 {
          text-transform: uppercase;
          letter-spacing: 3.19px;
          font-size: 14px;
        }

        p {
          margin-bottom: 0;
          width: 50%;
          font-weight: 300;
        }

        a {
          color: #fff !important;
        }
      }
    }

    .forms {
      .btn-outline-primary {
        border-radius: 100px;
        color: #fff;
        border: 1px solid #fff;
        padding: 15px 30px;

        &:hover {
          background-color: var(--secondary-color);
        }

        &:focus {
          box-shadow: none;
          border: 0.5px solid #fff;
        }
      }

      .form-group {
        margin-bottom: 30px;
        text-align: left;
        position: relative;

        p.error {
          margin-bottom: 0;
          color: #d83737;
          font-size: 12px;
          padding-top: 6px;
          font-weight: 600;
          text-transform: capitalize;
        }

        .form-control {
          border: 0.5px solid #ffffff56;
          border-radius: 7px;
          opacity: 0.88;
          background-color: transparent;
          padding: 14px 20px;
          min-height: 50px;
          font-weight: 200;
          color: #ffff;

          &:focus {
            box-shadow: none;
            border: 0.5px solid var(--secondary-color);
          }
        }
      }

      .form-control::-ms-input-placeholder {
        color: #fff;
      }

      .form-control::placeholder {
        color: #fff;
      }
    }
  }

  .map__container {
    iframe {
      width: 100%;
      height: 450px;
      filter: grayscale(1);
      border-radius: 0 0 40px 40px;
    }
  }
}

section.terms__conditions {
  padding-bottom: 60px;
  min-height: 50vh;

  h2 {
    text-transform: uppercase;
    font-weight: bold;
    color: var(--primary-color);
    margin-bottom: 10px;
  }

  .content {
    font-family: var(--secondary-font);
  }
}

section.search__lists {
  .search_loader {
    width: 100%;
    height: 128px;
    border-radius: 0px;
    background: linear-gradient(90deg, #eeeeee 25%, #dedede 50%, #eeeeee 75%);
    background-size: 200% 100%;
    animation: loading 2s infinite ease-in-out;
    margin-bottom: 20px;
  }

  @keyframes loading {
    0% {
      background-position: 200% 0;
    }

    100% {
      background-position: -200% 0;
    }
  }

  h2.search-header {
    margin-top: 40px;
    padding-left: 0;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 25px;
    font-family: var(--secondary-font);
    position: relative;
    width: fit-content;
    margin-bottom: 30px;

    &::after {
      content: "";
      width: 100%;
      height: 3px;
      background: #f28a3a;
      position: absolute;
      bottom: -4px;
      left: 0;
      border-radius: 10px;
    }
  }

  padding-bottom: 60px;

  .team__lists {
    .member {
      margin-top: 30px;
      text-align: center;
      border: 1px solid rgb(216, 213, 213);
      padding: 10px;
      border-radius: 15px;
      padding: 30px;

      .avatar img {
        height: 100px;
        width: 100px;
        border-radius: 100%;
        object-fit: cover;
      }

      h3 {
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 2px;
      }

      h6 {
        font-size: 15px;
        font-weight: 100;
        color: #9fa1aa;
      }

      p {
        font-size: 14px;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
      }
    }
  }

  .basic__lists {
    .image {
      margin: 15px 0;
      background: #f4f4f4;
      // padding: 10px;
      border-radius: 4px;

      img {
        width: 100%;
        height: 130px;
        object-fit: cover;
        border-radius: 4px;
      }
    }

    .content {
      h2 {
        font-size: 22px;
        text-transform: capitalize;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        font-size: 14px;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

section.page_not_found {
  margin-top: 100px;
  padding-bottom: 60px;

  .error {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    img {
      width: 440px;
      margin: 0 auto;
      object-fit: contain;
    }

    h3 {
      width: 30%;
      margin: 0 auto;
      font-size: 18px;
      text-align: center;
    }

    .back {
      background: var(--primary-color);
      display: inline-flex;
      height: 50px;
      width: 50px;
      border-radius: 100%;
      margin-top: 30px;

      img {
        padding: 17px;
      }
    }
  }
}

section.instagram__feed {
  padding-bottom: 60px;

  .feedGrid {
    position: relative;
    margin-top: 30px;

    img.posts {
      border-radius: 10px;
      position: relative;
      object-fit: cover;
      height: 250px;
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      -webkit-transition: all 0.5s ease;

      img {
        width: 50px;
      }
    }

    &:hover {
      cursor: pointer;

      .icon {
        opacity: 1;
        transition: 0.2s ease;
        z-index: 9;
      }

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #0000008a;
        left: 0;
        bottom: 0;
        border-radius: 10px;
      }
    }
  }
}

.captcha_sec {
  display: flex;
  align-items: center;
  position: relative;

  #captcha {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #e3e3e3;
    padding-left: 20px;
    padding-right: 40px;
    border-radius: 0 7px 7px 0;
  }

  .captcha_refresh {
    position: absolute;
    right: 5px;

    img {
      height: 20px;
    }
  }
}

.refresh_captcha {
  font-weight: 300;
  text-align: right;
  padding-right: 15px;
  padding-bottom: 5px;
  cursor: pointer;
  color: #ffffffc2;
  display: inline-block;
}

// GENERAL
.rtl__reverse .right__arrow img {
  rotate: 180deg;
}

.swiper-button-disabled {
  opacity: 0.3;
}

.dummy_image {
  text-align: center;
  margin-bottom: 60px;
}

section.volunteer_with_us,
.complaints__modal {

  .country__code {
    left: 10px;
    top: 50%;
    transform: translateY(-50%)
  }

  .phone__number {
    padding-left: 50px;
  }

  input {
    font-size: 14px;
  }

  .upload__cv {
    border: 2px dashed gainsboro;
    border-radius: 8px;
    padding: 40px;
    margin-bottom: 0 !important;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .supporting_documents {
    font-size: 11px;
    color: grey;
  }

  .upload_cv_text {
    font-size: 14px;
  }

  .submit {
    background-color: #05253d !important;
    color: white;
    padding: 10px 30px;
    border-radius: 30px;
  }

  .consent-checkbox {
    input {
      border-color: #f28a3a !important;
      // margin-top: 5px;
      width: 18px;
      height: 18px;
    }

    input:focus {
      box-shadow: none !important;
    }

    input:active {
      filter: none !important;
    }

    input:checked {
      background-color: #f28a3a !important;
    }
  }

  .consent-label-1 {
    margin-bottom: 0px !important;
  }

  .consent-label {
    font-size: 14px;
  }

  .error {
    color: red;
    font-size: 12px;
  }
}

.complaints__modal {

  .titleBar__section {
    margin-top: 0 !important;
    padding-top: 0 !important;
    margin-bottom: 20px !important;
  }

  .upload__cv {
    padding: 20px;
  }

  .select__country {
    font-size: 14px !important;
    color: #495057 !important;
  }

  .custom__upload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 !important;
    height: 35px !important;
    padding-left: 10px !important;

    label {
      margin-bottom: 0;
      font-size: 14px !important;
      color: #495057 !important;
    }
  }

  .upload__text {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: #f28a3a;
    padding: 5px 20px;
    font-size: 12px;
    color: white !important;
    text-align: center;
  }
}

.blogs {
  .blog__details {
    background-color: #f7f7f7;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 500;
  }

  .right__border {
    border-right: 1px solid lightgray;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .read__more__blogs {
    font-size: 10px;
  }
}
