@font-face {
  font-family: "Cairo";
  src: local("Cairo Regular"),
    url("../../public/fonts/Cairo-Regular.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Cairo";
  src: local("Cairo ExtraLight"),
    url("../../public/fonts/Cairo-ExtraLight.woff") format("woff");
  font-weight: 200;
}

@font-face {
  font-family: "Cairo";
  src: url("../../public/fonts/Cairo-Light.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "Cairo";
  src: url("../../public/fonts/Cairo-SemiBold.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "Cairo";
  src: url("../../public/fonts/Cairo-Bold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Cairo";
  src: url("../../public/fonts/Cairo-Black.woff") format("woff");
  font-weight: 800;
}

body.rtl {
  direction: rtl !important;
  font-family: "Cairo", sans-serif !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Cairo", sans-serif !important;
    font-weight: 600;
    text-align: right;
  }

  p {
    text-align: right;
  }
  ol, ul {
    padding-right: 2rem;
}
  p,
  a,
  .btn {
    font-family: "Cairo", sans-serif !important;
    font-weight: 400;
  }

  .no-data p {
    text-align: center;
  }

  .main__header {
    direction: ltr;
  }

  .announcement-container {
    direction: ltr !important;
    flex-direction: row-reverse;
  }

  .announcement-container .announcement-text {
    font-family: "Cairo", sans-serif !important;
  }

  .language__switch {
    li {
      margin-left: 10px;
      font-family: var(--primary-font);
      font-weight: 500;
      border-left: 2px solid var(--secondary-color);
      padding-left: 10px;

      &.active {
        color: var(--primary-color);
        font-weight: 600;
      }

      &.contact {
        border-left: none;

        a {
          font-weight: 600;
        }
      }
    }
  }

  .details-first-column {
    font-family: "Cairo", sans-serif !important;
  }

  .menu__items .menu__links ul li span {
    margin-left: 8px;
  }

  .menu__items .menu__links::after {
    right: auto;
    left: 50px !important;
  }

  .rtl__reverse {
    display: flex;
    flex-direction: row-reverse;
  }

  span.left__arrow {
    margin-right: 10px !important;
  }

  .btn-primary,
  button.btn.btn-secondary {
    text-align: right;
    min-width: 210px;

    &:hover {
      img {
        left: 18px;
        right: auto;
      }
    }

    img {
      right: auto;
      left: 25px;
      rotate: 180deg;
    }
  }

  .levent-grid .details-container {
    padding: 5px;
  }

  .event-grid h4 {
    right: 30px;
  }

  .event-grid .title {
    right: 30px;
  }

  .event-grid .arrow {
    right: auto;
    left: 10%;
    rotate: 180deg;
  }

  .phoneRtl {
    display: inline-block;
    direction: ltr;
  }

  footer.humanRights__footer .footer__widgets p {
    padding-left: 50px;
    padding-right: 0;

    span.phone {
      display: inline-block;
      direction: ltr;
    }
  }

  .titleBar__section ul li::after {
    right: -14px;
    left: auto;
    padding-right: 0;
  }

  .titleBar__section ul li {
    margin-right: 0;
    margin-left: 22px;
    padding-right: 0;
    padding-left: 0;
    font-family: "Cairo", sans-serif !important;
  }

  section.humanRights__about .about__featured h2 {
    font-size: 30px;
    padding-left: 30px;
    border-right: 0;
    border-left: none;
    margin: 0;
    padding-left: 0px;
    margin-bottom: 20px;
  }

  section.humanRights__about .vison__grids .grid__items .header img {
    height: 40px;
    padding-left: 10px;
  }

  section.faq .accordion-button {
    text-align: right;
  }

  section.whatWeDo .grid__lists .title h2 {
    padding-right: 20px;
  }

  section.library .latest__videos .featured__video h2 {
    left: auto;
    right: 5%;
  }

  section.faq .accordion-button::after {
    margin-left: 0;
    margin-right: auto;
  }

  section.contact_us .contact__details .address_grid .icon {
    padding-left: 20px;
    padding-right: 0px;
  }

  .contact__details {
    .forms {
      text-align: left !important;

      .text-right {
        text-align: left !important;
      }
    }
  }

  .menu__items .menu__links ul {
    margin-right: 0;
    margin-left: 28px;
  }

  .menu__items .header__logo .closeMenu {
    flex-direction: row-reverse;
  }

  section.local__events .localEvent__grid.sm__grid .localGrid .icon img {
    padding-left: 5px;
    padding-right: 0;
  }

  section.local__events .localGrid .icon img {
    padding-right: 0px;
    padding-left: 10px;
  }

  .announcement-container .marquee-dot {
    margin-left: 7px;
  }

  .intro-container img.intro-img {
    border-radius: 0 10px 10px 0;
    min-height: 330px;
    object-fit: cover;
  }

  section.humanRights__whatWeDo .what-we-do-text-container {
    right: 0;
  }

  section.whatWeDo .grid__lists .title {
    width: 100%;
  }

  section.whatWeDo .grid__lists .title h2 {
    line-height: 1.3 !important;
    font-weight: 600;
  }

  section.humanRights__about .vison__grids .grid__items {
    border-left: 1px solid #eee;
    border-right: none;
  }

  section.humanRights__about .vison__grids .grid__items:nth-child(even) {
    border-left: none;
  }

  footer.humanRights__footer .privacy span {
    margin-right: 20px;
    margin-left: 0;
  }

  .intro-container .title {
    font-family: "Cairo", sans-serif !important;
  }

  .announcement-container .announcement-marquee-container {
    flex-direction: row-reverse;
  }

  .announcement-container .announcement-marquee-container,
  .accordion-button {
    font-family: "Cairo", sans-serif !important;
  }

  .content {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px;
  }

  .localGrid {
    .icon {
      &:first-child {
        margin: 0;
        border: none;
        border-left: 0.5px solid #70707031;
        margin-left: 10px;
      }

      &:last-child {
        margin: 0;
        border: none;
        border-left: 0.5px solid #70707031;
        margin-left: 10px;
      }

      img {
        padding-left: 10px;
      }
    }
  }

  // RESPONSIVE
  @media only screen and (max-width: 767px) {
    .intro-container img.intro-img {
      border-radius: 10px 10px 0px 0;
    }
    .levent-grid .details-first-column img{
      padding-left: 8px;
      width: 30px;
    }

    footer.humanRights__footer {
      .privacy p {
        text-align: center !important;
      }

      .copyright p {
        text-align: center !important;
      }
    }
  }

  section.contact_us .contact__details .forms .form-group .form-control {
    direction: ltr;
    text-align: right;
  }

  section.humanRights__whatWeDo .grid__lists .title h2 {
    padding-left: 0;
    padding-right: 20px;
  }

  .captcha_sec {
    #captcha {
      right: auto;
      left: 0;
      border-radius: 7px 0 0 7px;
    }
  }

  .home__about a.know-more img {
    rotate: (180deg);
  }

}