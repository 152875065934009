.modal {
  &-form {
    &-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem;
      margin-bottom: 1.5rem;
      margin-top: 10px;

      @media (max-width: 640px) {
        grid-template-columns: 1fr;
      }
    }

    &-field {
      width: 100%;

      input {
        width: 100%;
        padding: 7px;
        font-size: 1rem;
        border: 1px solid #6f7071;
        border-radius: 5px;
        opacity: 0.6;

        &:focus {
          outline: none;
          border-color: #f4804f;
        }

        &::placeholder {
          color: #999;
        }
      }
    }

    &-phone {
      display: flex;
      gap: 0.5rem;

      .country-code {
        width: 80px;
        padding: 0.75rem;
        border: 1px solid #e5e7eb;
        border-radius: 4px;
        background: #f9fafb;
        text-align: center;
      }

      input {
        flex: 1;
      }
    }

    &-contact {
      margin: 2rem 0;

      .contact-label {
        font-weight: 500;
        margin-right: 2rem;
      }

      .checkbox-group {
        display: inline-flex;
        gap: 2rem;

        label {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          cursor: pointer;

          .type__checkbox {

            .form-check-input {
              border-color: #f4804f !important;
            }

            .form-check-input:checked {
              background-color: #f4804f !important;
              border-color: #f4804f !important;
              border-radius: 3px !important;
            }

            .form-check-input:focus {
              outline: none !important;
              box-shadow: none !important;
            }
            
          }
        }
      }
    }
  }

  &-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 2rem;
      border-radius: 2rem;
      font-size: 1rem;
      cursor: pointer;
      transition: all 0.2s;

      &.back {
        background: none;
        border: 1px solid #e5e7eb;
        color: #f4804f;

        &:hover {
          background: #f9fafb;
        }

        img{
          width: 50px;
          height: 1rem;
          margin-bottom: 0px;
          transform: rotate(180deg);
        }
      }

      &.next {
        background: #0a1f44;
        border: none;
        color: white;

        &:hover {
          background: darken(#0a1f44, 5%);
        }

        img{
          width: 50px;
          height: 1rem;
          margin-bottom: 0px;
        }
      }
    }
  }
}
