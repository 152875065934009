@media only screen and (max-width: 767px) {
  .dummy_image img{
    width: 100%;
  }
  #About_Us .logo__about {
    justify-content: start;
}
  section.humanRights__whatWeDo .whatWeDo__contents {
    padding: 0;
}
  section.humanRights__whatWeDo {
    .grid__lists {
      padding: 20px;
    }
    padding: 20px;
  }
  section.page_not_found .error {
    img {
      width: 70%;
    }
    .back img {
      padding: 9px;
    }
    h3 {
      width: 66%;
      margin: 0 auto;
      font-size: 14px;
      text-align: center;
    }
  }
  section.humanRights__whatWeDo .grid__lists .title {
    flex-direction: column;
    align-items: start;
    width: 100%;
    span {
      font-size: 45px;
    }
    h2 {
      padding-left: 0;
      margin-bottom: 20px;
      font-size: 22px;
    }
  }
  section.humanRights__whatWeDo .grid__lists img.whatwedo {
    height: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  section.events .event__grid .content p {
    font-size: 18px !important;
  }
  .levent-grid h6 {
    min-height: 36px;
  }
  .logo_loader {
    width: 115px;
    height: 40px;
  }
  section.humanRights__news .news-grid img {
    height: 210px;
  }
  .levent-grid .event__img img {
    height: 210px;
  }
  section.instagram__feed .feedGrid img.posts {
    height: 210px;
    object-fit: cover;
  }
  section.humanRights__library .library__grid {
    height: 210px;
  }
  .intro-container img.intro-img {
    border-radius: 10px 10px 0px 0;
  }
  section.search__lists h2.search-header {
    margin-top: 30px;
    padding-left: 0;
    font-size: 18px;
    margin-bottom: 10px;
  }
  section.search__lists .basic__lists .image img {
    width: 100%;
    height: 185px;
  }
  .Titlebar_btn {
    flex-direction: column;
    align-items: start !important;
  }
  section.search__lists {
    .search_loader {
      width: 100%;
      height: 70px;
    }
    .sm-d-block .col-md-12 {
      width: 100%;
    }

    .sm-d-block {
      display: block;
      width: 100%;
    }
    .no-data {
      padding: 30px;
      p {
        width: 100%;
        text-align: center;
      }
    }
  }
  section.local__events .localGrid .icon {
    margin-bottom: 0 !important;
    img,
    p {
      margin-top: 10px;
    }
  }
  section.local__events .localGrid .icon p {
    font-size: 12px !important;
  }
  .details_loader {
    height: 300px;
  }
  .banner_loader {
    width: 100%;
    height: 60vh;
  }
  .details-first-column.d-flex.align-items-start {
    padding-left: 10px;
  }
  .levent-grid .details-first-column {
    display: flex;
    align-items: center;
    padding: 0;
    padding-bottom: 3px;
    img {
      width: 17px;
      height: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      object-fit: contain;
      margin: 0 !important;
      padding-right: 5px;
    }
  }
  .menu__items .menu__contact {
    border-top: 1px solid #ffffff63;
    margin-top: 40px;
    padding-top: 50px;
  }
  .menu__items .menu__links ul li span {
    font-size: 10px;
  }
  .call__action img {
    height: 48px;
  }
  p {
    font-size: 14px !important;
  }
  button.btn.btn-primary {
    font-size: 14px;
  }
  section.humanRights__banner {
    padding: 24px;
    height: 430px;
    .banner__content {
      width: 100%;
      h1 {
        font-size: 30px;
        line-height: 1.1;
      }
    }
  }
  .announcement-container {
    margin: 15px 0;
  }
  .intro-container .description {
    font-size: 14px !important;
  }
  #About_Us {
    padding: 37px 0 !important;
    .heading {
      font-size: 30px !important;
      line-height: 1.1;
      margin-bottom: 10px;
    }
    .description {
      font-size: 14px !important;
      margin: 15px 0;
    }
  }
  section.humanRights__whatWeDo .what-we-do-text-container {
    bottom: 0 !important;
    position: relative !important;
    height: 350px !important;
  }
  .d-flex.align-items-end.justify-content-end.position-relative.mb-5.what-we-do-container {
    display: block !important;
  }
  .d-flex.align-items-end.justify-content-end.position-relative.mb-5.what-we-do-container
    img.lg-image {
    width: 100%;
    height: 300px;
    border-radius: 20px 20px 0px 0px;
  }
  .what-we-do-text-container {
    border-radius: 0px 0px 20px 20px !important;
  }
  .levent-grid .details-container {
    padding: 10px !important;
  }
  footer.humanRights__footer {
    .footer__widgets .logo img {
      width: 212px !important;
    }
    .footer__widgets {
      padding: 0 20px;
      min-height: 160px !important;
      margin-bottom: 30px !important;
    }
    .footer__widgets h4 {
      margin-bottom: 20px;
    }
    .privacy {
      text-align: center;
    }
    .copyright {
      display: block !important;
      text-align: center;
      p {
        font-size: 12px !important;
      }
    }
  }

  // FAQ
  section.faq {
    .accordion-button {
      padding: 30px;
    }
    .faq__details {
      padding: 20px 10px;
    }
  }

  // ABOUT US
  .button.btn.btn-secondary {
    font-size: 14px !important;
  }
  .titleBar__section {
    display: flex;
    width: 100%;
    align-items: start !important;
    min-height: 70px !important;
    flex-direction: column;
    h2 {
      font-size: 28px !important;
    }
    ul {
      li {
        font-size: 14px;
      }
    }
  }

  section.humanRights__about {
    .about__featured {
      padding: 40px !important;
      h2 {
        border-right: none;
        padding-right: 0;
        margin-bottom: 0px;
      }
    }
    .vison__grids .grid__items {
      padding: 40px;
    }
  }
  section.whatWeDo {
    .titleBar__section {
      margin-bottom: 0;
    }
    .grid__lists:hover {
      padding: 35px;
    }
    .grid__lists {
      padding: 35px;
      padding-top: 0;
      &:nth-child(2) .row {
        flex-direction: column-reverse;
      }
      &:nth-child(4) .row {
        flex-direction: column-reverse;
      }
      .title {
        display: flex;
        align-items: start;
        width: 100%;
        flex-direction: column;
        margin-bottom: 20px;
        span {
          font-size: 60px;
        }
        h2 {
          padding-left: 0;
          font-size: 26px;
        }
      }
    }
  }
  section.news .featured_news .news__grid img {
    width: 100%;
    height: 250px;
  }
  .report__details img {
    height: 250px;
  }
  section.library .nav-tabs {
    justify-content: center;
  }
  section.library .latest__videos .featured__video {
    height: 314px;
    //     width: 93%;
    // margin: 0 auto;
    // margin-bottom: 40px;
    h2 {
      position: absolute;
      color: #fff;
      font-weight: 400;
      width: 82%;
      font-size: 23px;
      line-height: 1.1;
      left: 6%;
      bottom: 7%;
    }
  }
  section.library .latest__videos .featured__video .icon img {
    height: 51px;
  }
  .library__reports h2 {
    min-height: 16px;
  }
  section.contact_us .contact__details .address_grid {
    padding-left: 0;
    padding-right: 0;
  }
  section.contact_us {
    .contact__details {
      .address_grid .icon {
        padding-right: 15px;
        padding-bottom: 15px;
      }
      padding: 38px;
      .address_grid {
        margin-bottom: 20px;
        .icon img {
          height: 50px;
        }
        .content {
          p {
            width: 100%;
          }
        }
        &:last-child {
          margin-bottom: 40px;
        }
      }
    }
  }
  .map__container {
    iframe {
      width: 100%;
      height: 250px;
    }
  }
  .menu__items .menu__links .search__box {
    width: 100%;
    padding-top: 100px;
  }
  section.news__details .details img {
    height: 250px;
  }
  section.local__events .localEvent__grid img.main {
    height: 312px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1023px) and (orientation: landscape) {
  section.page_not_found .error h3 {
    width: 44%;
  }
  .menu__items .menu__links ul li {
    font-size: 18px;
  }
  .menu__items .header__logo .logo img {
    width: 178px;
  }
  .menu__items .menu__links::after {
    display: none;
  }
}
/* TABLET */

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .dummy_image img{
    width: 100%;
  }
  section.humanRights__whatWeDo {
    padding: 20px 16px;
  }
  section.humanRights__whatWeDo .grid__lists .title {
    span {
      font-size: 50px;
    }
    h2 {
      font-size: 25px;
    }
  }
  section.instagram__feed .feedGrid img.posts {
    height: 115px;
}
  section.humanRights__whatWeDo .grid__lists .title {
    width: 100%;
    margin: 20px 0;
  }
  section.page_not_found .error h3 {
    width: 44%;
  }
  .col-tab-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .banner_loader {
    width: 100%;
    height: 40vh;
  }
  .intro-container img {
    height: 300px;
    object-fit: cover;
    border-radius: 20px 20px 0 0;
  }
  button.btn.btn-primary {
    font-size: 14px;
  }
  section.humanRights__banner {
    padding: 24px;
    height: 430px;
    .banner__content {
      width: 100%;
      h1 {
        font-size: 40px;
        line-height: 1.1;
      }
    }
  }
  .announcement-container {
    margin: 15px 0;
  }
  .intro-container .description {
    font-size: 14px !important;
  }
  #About_Us {
    padding: 37px 0 !important;
    .heading {
      font-size: 30px !important;
      line-height: 1.1;
      margin-bottom: 10px;
    }
    .description {
      font-size: 14px !important;
      margin: 15px 0;
    }
  }
  section.humanRights__library .library__grid {
    height: 105px;
  }
  footer.humanRights__footer .footer__widgets p {
    padding-right: 0;
    font-size: 14px;
  }
  footer.humanRights__footer .col-md-3 {
    width: 50%;
  }

  section.humanRights__whatWeDo .what-we-do-text-container {
    bottom: 0 !important;
    position: relative !important;
    height: 350px !important;
    width: 100%;
    border-radius: 0 0 20px 20px;
  }
  .d-flex.align-items-end.justify-content-end.position-relative.mb-5.what-we-do-container
    img.lg-image {
    width: 100%;
    border-radius: 20px 20px 0 0;
    height: 400px;
  }
  .d-flex.align-items-end.justify-content-end.position-relative.mb-5.what-we-do-container {
    display: block !important;
  }

  .levent-grid .details-container {
    padding: 10px !important;
  }
  footer.humanRights__footer {
    .footer__widgets .logo img {
      width: 212px !important;
    }
    .footer__widgets {
      padding: 0 20px;
      min-height: 160px;
      margin-bottom: 30px !important;
    }
    .footer__widgets h4 {
      margin-bottom: 20px;
    }
    .privacy {
      text-align: center;
    }
    .copyright {
      display: block !important;
      text-align: center;
      p {
        font-size: 12px !important;
      }
    }
  }

  // ABOUT US
  .button.btn.btn-secondary {
    font-size: 14px !important;
  }
  .titleBar__section {
    display: flex;
    width: 100%;
    align-items: center !important;
    min-height: 80px !important;
    margin-bottom: 30px;
    h2 {
      font-size: 28px !important;
    }
    ul {
      li {
        font-size: 14px;
      }
    }
  }

  section.humanRights__about {
    .about__featured {
      padding: 40px !important;
    }
    .vison__grids .grid__items {
      padding: 40px;
    }
  }
  section.whatWeDo {
    .titleBar__section {
      margin-bottom: 0;
    }
    .grid__lists {
      padding: 35px;
      padding-top: 0;

      .title {
        display: flex;
        align-items: start;
        width: 100%;
        flex-direction: column;
        margin-bottom: 20px;
        span {
          font-size: 60px;
        }
        h2 {
          padding-left: 0;
          font-size: 26px;
        }
      }
    }
  }
  section.news .news__items .news__grid img {
    width: 100%;
    height: 133px;
  }
  section.news .featured_news .news__grid img {
    width: 100%;
    height: 250px;
  }
  .report__details img {
    height: 250px;
  }
  .library__reports .library__image img {
    height: 133px;
  }
  section.library .latest__videos .videos__grid {
    height: 133px;
  }
  section.library .library__grid .library__item {
    height: 133px;
  }
  section.library .nav-tabs {
    justify-content: center;
  }
  section.library .latest__videos .featured__video {
    height: 314px;
    h2 {
      position: absolute;
      color: #fff;
      font-weight: 400;
      width: 82%;
      font-size: 23px;
      line-height: 1.1;
      left: 6%;
      bottom: 7%;
    }
  }
  section.library .latest__videos .featured__video .icon img {
    height: 51px;
  }
  .library__reports h2 {
    min-height: 16px;
  }
  section.contact_us .contact__details .address_grid {
    padding-left: 0;
    padding-right: 0;
    align-items: center;
  }
  section.contact_us {
    .contact__details {
      .address_grid .icon {
        padding-right: 0;
        padding-bottom: 15px;
        margin-right: 20px;
      }
      padding: 60px;
      .address_grid {
        .content {
          p {
            width: 100%;
          }
        }
        &:last-child {
          margin-bottom: 40px;
        }
      }
    }
  }
  .map__container {
    iframe {
      width: 100%;
      height: 250px;
    }
  }
  .menu__items .menu__links .search__box {
    width: 100%;
    padding-top: 100px;
  }
  section.news__details .details img {
    height: 250px;
  }
  .menu__items .col-md-4,
  .col-md-8 {
    width: 100%;
  }
  .menu__items {
    overflow-y: auto;
  }
  .menu__items .menu__links::after {
    display: none;
  }
  .menu__items .menu__links {
    height: 47vh;
  }
  .menu__items .menu__contact {
    margin-top: 50px;
    min-height: 24vh;
  }
  .menu__items .menu__links ul li {
    font-size: 20px;
    font-family: var(--secondary-font);
    font-weight: 400;
    padding-bottom: 22px;
    display: flex;
    align-items: center;
  }
}

/* IPAD PRO */
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .dummy_image img{
    width: 100%;
  }
  section.humanRights__whatWeDo .grid__lists .title {
    width: 100%;
  }
  .col-tab-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .banner_loader {
    width: 100%;
    height: 40vh;
  }
  section.humanRights__whatWeDo .what-we-do-text-container {
    bottom: 0 !important;
    position: relative !important;
    height: 350px !important;
    width: 100%;
    border-radius: 0 0 20px 20px;
  }
  .d-flex.align-items-end.justify-content-end.position-relative.mb-5.what-we-do-container
    img.lg-image {
    width: 100%;
    border-radius: 20px 20px 0 0;
    height: 400px;
  }
  .intro-container img {
    height: 300px;
    object-fit: cover;
  }
  button.btn.btn-primary {
    font-size: 14px;
  }
  section.humanRights__banner {
    padding: 24px;
    height: 430px;
    .banner__content {
      width: 100%;
      h1 {
        font-size: 40px;
        line-height: 1.1;
      }
    }
  }
  .announcement-container {
    margin: 15px 0;
  }
  .intro-container .description {
    font-size: 14px !important;
  }
  #About_Us {
    padding: 37px 0 !important;
    .heading {
      font-size: 30px !important;
      line-height: 1.1;
      margin-bottom: 10px;
    }
    .description {
      font-size: 14px !important;
      margin: 15px 0;
    }
  }
  section.humanRights__library .library__grid {
    height: 173px;
  }
  footer.humanRights__footer .footer__widgets p {
    padding-right: 0;
    font-size: 14px;
  }
  footer.humanRights__footer .col-md-3 {
    width: 50%;
  }

  section.humanRights__whatWeDo .what-we-do-text-container {
    bottom: 0 !important;
    position: relative !important;
    height: 350px !important;
    width: 100%;
  }
  .d-flex.align-items-end.justify-content-end.position-relative.mb-5.what-we-do-container {
    display: block !important;
  }
  .d-flex.align-items-end.justify-content-end.position-relative.mb-5.what-we-do-container
    img.lg-image {
    width: 100%;
  }
  .levent-grid .details-container {
    padding: 10px !important;
  }
  footer.humanRights__footer {
    .footer__widgets .logo img {
      width: 212px !important;
    }
    .footer__widgets {
      padding: 0 20px;
      min-height: 160px;
      margin-bottom: 30px !important;
    }
    .footer__widgets h4 {
      margin-bottom: 20px;
    }
    .privacy {
      text-align: center;
    }
    .copyright {
      display: block !important;
      text-align: center;
      p {
        font-size: 12px !important;
      }
    }
  }

  // ABOUT US
  .button.btn.btn-secondary {
    font-size: 14px !important;
  }
  .titleBar__section {
    display: flex;
    width: 100%;
    align-items: start !important;
    min-height: 130px;
    flex-direction: column;
    margin-bottom: 30px;
    h2 {
      font-size: 28px !important;
    }
    ul {
      li {
        font-size: 14px;
      }
    }
  }

  section.humanRights__about {
    .about__featured {
      padding: 40px !important;
    }
    .vison__grids .grid__items {
      padding: 40px;
    }
  }
  section.whatWeDo {
    .titleBar__section {
      margin-bottom: 0;
    }
    .grid__lists {
      padding: 35px;
      padding-top: 0;

      .title {
        display: flex;
        align-items: start;
        width: 100%;
        flex-direction: column;
        margin-bottom: 20px;
        span {
          font-size: 60px;
        }
        h2 {
          padding-left: 0;
          font-size: 26px;
        }
      }
    }
  }
  section.news .news__items .news__grid img {
    width: 100%;
    height: 133px;
  }
  section.news .featured_news .news__grid img {
    width: 100%;
    height: 250px;
  }
  .report__details img {
    height: 450px;
  }
  .library__reports .library__image img {
    height: 133px;
  }
  section.library .latest__videos .videos__grid {
    height: 133px;
  }
  section.library .library__grid .library__item {
    height: 133px;
  }
  section.library .nav-tabs {
    justify-content: center;
  }
  section.library .latest__videos .featured__video {
    height: 314px;
    h2 {
      position: absolute;
      color: #fff;
      font-weight: 400;
      width: 82%;
      font-size: 23px;
      line-height: 1.1;
      left: 6%;
      bottom: 7%;
    }
  }
  section.library .latest__videos .featured__video .icon img {
    height: 51px;
  }
  .library__reports h2 {
    min-height: 16px;
  }
  section.contact_us .contact__details .address_grid {
    padding-left: 0;
    padding-right: 0;
    align-items: center;
  }
  section.contact_us {
    .contact__details {
      .address_grid .icon {
        padding-right: 0;
        padding-bottom: 15px;
        margin-right: 20px;
      }
      padding: 60px;
      .address_grid {
        .content {
          p {
            width: 100%;
          }
        }
        &:last-child {
          margin-bottom: 40px;
        }
      }
    }
  }
  .map__container {
    iframe {
      width: 100%;
      height: 250px;
    }
  }
  .menu__items .menu__links .search__box {
    width: 100%;
    padding-top: 100px;
  }
  section.news__details .details img {
    height: 450px;
  }
  .menu__items .col-md-4,
  .col-md-8 {
    width: 100%;
  }
  .menu__items {
    overflow-y: auto;
  }
  .menu__items .menu__links::after {
    display: none;
  }
  .menu__items .menu__links {
    height: 47vh;
  }
  .menu__items .menu__contact {
    margin-top: 50px;
    min-height: 24vh;
  }
}

/* 13 INCH SCREEN */
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  section.whatWeDo .grid__lists .title {
    width: 100%;
  }
}
