footer.humanRights__footer {
  padding-top: 80px;
  background-color: var(--primary-color);
  color: #fff;

  .privacy {
    p {
      font-size: 14px;
      margin-bottom: 5px;
      opacity: 0.94;
      a {
        color: #fff;
        &:hover {
          color: var(--secondary-color);
        }
      }
    }
    span {
      margin-left: 20px;
    }
  }
  .footer__widgets.footer__lg {
    min-height: 220px;
  }
  .footer__widgets {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    a{
      color: #fff;
      &:hover{
        color: var(--secondary-color);
      }
    }
    p {
      padding-right: 50px;
      font-size: 14px;
      opacity: 0.94;
    }
    h4 {
      font-family: var(--primary-font);
      font-weight: 400;
      margin-bottom: 20px;
    }
    ul.social__links {
      list-style-type: none;
      padding: 0;
      margin-bottom: 0;
      display: inline-flex;
      li {
        padding: 5px;
        img {
          height: 40px;
          &:hover {
            cursor: pointer;
            transition: 0.5s all;
            transform: translateY(-2px);
          }
        }
      }
    }
    .logo {
      img {
        width: 250px;
      }
    }
    .footer__navLinks {
      display: flex;
      color: #fff;
      justify-content: space-between;
      ul {
        list-style-type: none;
        padding: 0;
        min-width: 33%;
        li {
          padding-bottom: 7px;
          font-size: 15px;
          opacity: 0.94;
          font-family: var(--secondary-font);
          a {
            color: #fff;
            &:hover {
              color: var(--secondary-color);
            }
          }
        }
      }
    }
  }

  .bottom__footer {
    background-color: #061d2d;
    .copyright {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      p {
        margin-bottom: 0;
        font-size: 14px;
        opacity: 0.94;
      }
    }
  }

  img.smarthatch {
    height: 13px;
    padding: 0 4px;
  }
}

.call__action {
  position: fixed;
  bottom: 100px;
  right: 40px;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 0 6px 15px #404f680d;
  z-index: 999;
  img {
    height: 55px;
  }
}
