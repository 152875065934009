section.humanRights__banner {
  padding: 50px;
  height: 580px;
  width: 100%;
  background-size: cover;
  object-fit: cover;
  border-radius: 22px;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 110px;

  .overlay {
    background-color: #000;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    opacity: 0.33;
    border-radius: 22px;
  }
  .banner__content {
    z-index: 99;
    color: #fff;
    width: 65%;
    padding-bottom: 25px;
    h1 {
      font-size: 70px;
      font-family: var(--secondary-font);
      font-weight: 700;
      line-height: 1;
      margin: 0;
      text-transform: uppercase;
      padding-bottom: 10px;
    }
    p {
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

// PAGINATION
.swiper-pagination-bullet-active {
  background-color: var(--secondary-color);
}
.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}
.swiper-pagination {
  bottom: 20px !important;
}
.home__banner{
    border-radius: 20px;
}

// ANIMATION

/* Animation */

@keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.animated {
    animation-duration: 2s;
    animation-fill-mode: both;
    -webkit-animation-duration: 2s;
    -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}

.banner_loader{
  width: 100%;
  height: 81vh;
  border-radius: 20px;
  margin-top: 120px;
  background: linear-gradient(90deg, #EEEEEE 25%, #DEDEDE 50%, #EEEEEE 75%);
  background-size: 200% 100%;
  animation: loading 2s infinite ease-in-out;
}
@keyframes loading {
  0% {
      background-position: 200% 0;
  }
  100% {
      background-position: -200% 0;
  }
}