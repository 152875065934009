.levent-grid {
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
  // background-color: red;
  // overflow: hidden;
  .event__img img{
    filter: grayscale(1);
    transition: 0.5s;
  }
  &:hover img{
    filter: grayscale(0);
    cursor: pointer;
    transition: 0.5s;
  }
  h6 {
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .content {
    font-size: 14px;
    margin-top: 5px;
  }
  .event__img {
    transition: 0.5s;
    img {
      width: 100%;
      height: 235px;
      object-fit: cover;
      border-radius: 10px;
      margin-bottom: 30px;
    }
  }

  .details-container {
    // height: 51px;
    padding: 5px 0;
    background: #f7f7f7 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    max-width: 100% !important;
    font-size: 11px;
    font-family: var(--primary-font);
    font-size: 12px;
    font-weight: 300;
    color: var(--primary-color);
  }

  .details-first-column {
    // border-right: 1px solid #ececec;
    font-family: var(--primary-font);
    font-size: 11px;
    font-weight: 400;
    padding-left: 7px;
    padding-bottom: 3px;
    color: var(--primary-color);
  }

  p {
    color: #000000;
    opacity: 1;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 0;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
