.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

:root {
  --blue: #6495ed;
  --white: #faf0e6;
  --primary: #04253d;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.background-primary {
  background-color: var(--primary);
}

.main-container {
  padding: 20px 50px;
}

.container {
  margin: 10px 0;
  /* margin-left: 10px !important;
  margin-right: 10px !important; */
}

.cursor-pointer {
  cursor: pointer;
}

.height-100 {
  height: 100% !important;
}

/* ANIMATIONS */
/* -webkit-animation: fadein 2s; 
-moz-animation: fadein 2s; 
 -ms-animation: fadein 2s; 
  -o-animation: fadein 2s; 
     animation: fadein 2s; */
     
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}

.text-none{
  text-transform: none !important;
}

.no-data {
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px;
  border-radius: 5px;
}
.no-data img{
  height: 40px;
  margin-bottom: 10px;
}
.no-data h4{
  font-size: 22px;
  margin-bottom: 10px;
  font-family: var(--font-secondary);
  text-transform: capitalize;
}
.no-data p{
  width: 40%;
  text-transform: capitalize;
  text-align: center;
}

/* DETAILS LOADER*/
.details_loader {
  width: 100%;
  height: 500px;
  border-radius: 0px;
  background: linear-gradient(90deg, #eeeeee 25%, #dedede 50%, #eeeeee 75%);
  background-size: 200% 100%;
  animation: loading 2s infinite ease-in-out;
  border-radius: 10px;
  margin-bottom: 15px;
}

.logo_loader{
  width: 200px;
  height: 60px;
  border-radius: 0px;
  background: linear-gradient(90deg, #eeeeee 25%, #dedede 50%, #eeeeee 75%);
  background-size: 200% 100%;
  animation: loading 2s infinite ease-in-out;
  margin-left: 10px;
  border-radius: 5px;
}
@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

/* DUMMY IMAGE */
.dummy__img img{
  border-radius: 10px;
}